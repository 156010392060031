/* Global CSS */

/*noinspection ALL*/
body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: "Roboto", sans-serif;
  -moz-osx-font-smoothing: "Roboto", sans-serif;
  font-style: normal;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}
img {
  width: 100%;
}
:root {
  --primary: #32a3bf; /* Hoofdkleur: een frisse blauwgroene tint */
  --heading-color: #1e4d5a; /* Kopkleur: diep blauw voor contrast */
  --bg-shade: #eaf7fa; /* Achtergrond: zeer lichte blauwtint */
  --accent: #4dcfa8; /* Accent: levendige groene tint */
  --dark-shade: #12363a; /* Donkere tint: bijna zwart blauwgroen voor diepe contrasten */
  --light-shade: #b0e5e8; /* Lichtere tint: pastel blauwgroen voor highlights */
  --secondary: #2e8b79; /* Secundaire kleur: een diepere blauwgroene tint */
  --white: #ffffff; /* Zuiver wit */
  --black: #000000; /* Zuiver zwart */


}

/* Existing Themes */

/* Purple Night Theme */
body.purple-night {
  --primary: #7e57c2;
  --heading-color: #512da8;
  --bg-shade: #ede7f6;
  --accent: #ff4081;
  --dark-shade: #311b92;
  --light-shade: #d1c4e9;
  --secondary: #9575cd;
  --white: #ffffff;
  --black: #000000;
}

/* Warm Orange Theme */
body.warm-orange {
  --primary: #ff7043;
  --heading-color: #bf360c;
  --bg-shade: #fff3e0;
  --accent: #ffca28;
  --dark-shade: #e65100;
  --light-shade: #ffccbc;
  --secondary: #ffab91;
  --white: #ffffff;
  --black: #000000;
}

/* Light Green Theme */
body.light-green {
  --primary: #8bc34a;
  --heading-color: #5a8b32;
  --bg-shade: #f1f8e9;
  --accent: #fdd835;
  --dark-shade: #33691e;
  --light-shade: #dcedc8;
  --secondary: #aed581;
  --white: #ffffff;
  --black: #000000;
}

/* New Themes */

/* Ocean Blue Theme */
body.ocean-blue {
  --primary: #0288d1; /* Main blue tone */
  --heading-color: #01579b; /* Dark blue for headings */
  --bg-shade: #e1f5fe; /* Light blue for background */
  --accent: #00acc1; /* Aqua tone for accents */
  --dark-shade: #004d40; /* Deep green-blue for contrast */
  --light-shade: #b3e5fc; /* Soft pastel blue for highlights */
  --secondary: #4dd0e1; /* Light aqua for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Sunset Red Theme */
body.sunset-red {
  --primary: #d84315; /* Vibrant sunset red */
  --heading-color: #bf360c; /* Deep red for headings */
  --bg-shade: #fbe9e7; /* Soft peach background */
  --accent: #ff8a65; /* Light red-orange accent */
  --dark-shade: #c62828; /* Dark red for contrast */
  --light-shade: #ffccbc; /* Light peach for highlights */
  --secondary: #ff7043; /* Soft red for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Forest Green Theme */
body.forest-green {
  --primary: #4caf50; /* Forest green */
  --heading-color: #2e7d32; /* Dark green for headings */
  --bg-shade: #e8f5e9; /* Light green background */
  --accent: #66bb6a; /* Bright green for accents */
  --dark-shade: #1b5e20; /* Deep green for contrast */
  --light-shade: #c8e6c9; /* Soft green for highlights */
  --secondary: #81c784; /* Pastel green for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Mystic Pink Theme */
body.mystic-pink {
  --primary: #ec407a; /* Bold pink */
  --heading-color: #c2185b; /* Deep pink for headings */
  --bg-shade: #fce4ec; /* Light pink background */
  --accent: #f48fb1; /* Light pink accent */
  --dark-shade: #880e4f; /* Dark purple-pink for contrast */
  --light-shade: #f8bbd0; /* Soft pastel pink for highlights */
  --secondary: #e91e63; /* Bright pink for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Coral Pink Theme */
body.coral-pink {
  --primary: #ff6f61; /* Bright coral pink */
  --heading-color: #d84315; /* Deep coral for headings */
  --bg-shade: #ffebee; /* Light pink background */
  --accent: #ff8a65; /* Soft coral accent */
  --dark-shade: #bf360c; /* Dark coral for contrast */
  --light-shade: #ffe0b2; /* Light peach for highlights */
  --secondary: #ffab91; /* Soft peach for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Midnight Blue Theme */
body.midnight-blue {
  --primary: #283593; /* Deep blue */
  --heading-color: #1a237e; /* Dark blue for headings */
  --bg-shade: #e8eaf6; /* Soft blue background */
  --accent: #3f51b5; /* Bright blue accent */
  --dark-shade: #0d47a1; /* Dark navy blue for contrast */
  --light-shade: #c5cae9; /* Light pastel blue for highlights */
  --secondary: #7986cb; /* Soft blue for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Golden Sunrise Theme */
body.golden-sunrise {
  --primary: #ffa726; /* Golden orange */
  --heading-color: #fb8c00; /* Deep orange for headings */
  --bg-shade: #fff3e0; /* Light golden background */
  --accent: #ffd54f; /* Bright yellow accent */
  --dark-shade: #e65100; /* Dark orange for contrast */
  --light-shade: #ffecb3; /* Light yellow for highlights */
  --secondary: #ffb74d; /* Soft orange for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Lavender Bliss Theme */
body.lavender-bliss {
  --primary: #9575cd; /* Soft lavender */
  --heading-color: #7e57c2; /* Deep lavender for headings */
  --bg-shade: #ede7f6; /* Light lavender background */
  --accent: #b39ddb; /* Bright lavender accent */
  --dark-shade: #512da8; /* Dark purple for contrast */
  --light-shade: #d1c4e9; /* Light purple for highlights */
  --secondary: #ab47bc; /* Soft purple for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Tropical Teal Theme */
body.tropical-teal {
  --primary: #00bfa5; /* Bright teal */
  --heading-color: #00897b; /* Deep teal for headings */
  --bg-shade: #e0f2f1; /* Light teal background */
  --accent: #26c6da; /* Bright aqua accent */
  --dark-shade: #004d40; /* Dark teal for contrast */
  --light-shade: #b2dfdb; /* Soft pastel teal for highlights */
  --secondary: #4db6ac; /* Soft aqua for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Crimson Red Theme */
body.crimson-red {
  --primary: #d32f2f; /* Deep crimson red */
  --heading-color: #b71c1c; /* Dark red for headings */
  --bg-shade: #ffcdd2; /* Soft pink-red background */
  --accent: #e57373; /* Bright red accent */
  --dark-shade: #c62828; /* Dark red for contrast */
  --light-shade: #ef9a9a; /* Light pink-red for highlights */
  --secondary: #f44336; /* Bright red for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Ice Blue Theme */
body.ice-blue {
  --primary: #81d4fa; /* Light icy blue */
  --heading-color: #0288d1; /* Deep blue for headings */
  --bg-shade: #e1f5fe; /* Light blue background */
  --accent: #29b6f6; /* Bright blue accent */
  --dark-shade: #0277bd; /* Dark blue for contrast */
  --light-shade: #b3e5fc; /* Soft pastel blue for highlights */
  --secondary: #4fc3f7; /* Soft aqua blue for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Pastel Peach Theme */
body.pastel-peach {
  --primary: #ffcc80; /* Soft peach */
  --heading-color: #ffb74d; /* Deeper peach for headings */
  --bg-shade: #fff8e1; /* Light peach background */
  --accent: #ffab40; /* Bright peach accent */
  --dark-shade: #fb8c00; /* Dark peach for contrast */
  --light-shade: #ffe0b2; /* Light peach for highlights */
  --secondary: #ffcc80; /* Soft peach for secondary elements */
  --white: #ffffff;
  --black: #000000;
}

/* Royal Purple Theme */
body.royal-purple {
  --primary: #673ab7; /* Royal purple */
  --heading-color: #4527a0; /* Deep purple for headings */
  --bg-shade: #ede7f6; /* Light purple background */
  --accent: #7e57c2; /* Bright purple accent */
  --dark-shade: #311b92; /* Dark purple for contrast */
  --light-shade: #d1c4e9; /* Soft pastel purple for highlights */
  --secondary: #9575cd; /* Soft lavender for secondary elements */
  --white: #ffffff;
  --black: #000000;
}


.btn {
  font-family: "Roboto";
  display: inline-block;
  padding: 14px 32px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  border-radius: 4px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -ms-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
}
.btn-outline-primary {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
.btn-outline-primary:hover {
  color: var(--white);
  background-color: var(--primary);
}
.btn-primary {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}
.btn-primary:hover {
  color: var(--primary);
  background-color: var(--white);
}
.btn-github {
  color: var(--white);
  background-color: var(--dark-shade);
  border: var(--dark-shade);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.95);
  gap: 16px;
}
.btn-github:hover {
  color: var(--dark-shade);
  background-color: var(--white);
}
/* Global CSS Ends */
/* Main Headings */

/* Heading 1 */
h1 {
  font-size: 56px;
  font-weight: 700;
  line-height: 67px;
}
/* Heading 2 */
h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 58px;
}
/* Heading 3 */
h2 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
}

/* Pragraphs text large medium small */
/* Body 1 */
.text-lg {
  color: var(--dark-shade);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
/* Body 2 */
.text-md {
  color: var(--dark-shade);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
/* Body 3 */
.text-sm {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

/* Section Titles */
/* Skills & About Me */
.section-title {
  color: var(--heading-color);
  font-size: 21px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
  display: flex;
  align-items: flex-start;
}
/* Portfolio, MySkillsScore & Contact Me */
.sub-title {
  color: var(--heading-color);
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
/* Section Titles Ends */

/* Navbar Style Start */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 85.333px;
  background: var(--white);
  box-shadow: 0px 5.333px 80px 0 rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}
.navbar-items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.navbar-items ul > li > a {
  text-decoration: none;
}
/* Navbar Content */
.navbar-content {
  color: var(--dark-shade);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  cursor: pointer;
}
.navbar-active-content {
  color: var(--primary);
}
.navbar-img {
  height: auto;
  width: 8vw;
  max-width: 100px;
  min-width: 60px;
}

.navbar-logo {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.dark-mode-switch{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100px;
}
.navbar-items {
  display: contents;
}

/* Navbar Styler Ends */
/* Hero Section Style */
.hero-section {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 133.333px 85.333px 0 85.333px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  background-color: var(--bg-shade);
}
.hero-section-content-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
}
.hero-section-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.333px;
}
.hero--section-content--box > button {
  margin-top: 21.333px;
}
.hero-section-title {
  color: var(--heading-color);
  font-size: 74.667px;
  font-weight: 700;
  line-height: 90px;
  align-self: stretch;
}
.hero-section-title-color {
  color: var(--primary);
}
.hero-section-description {
  color: var(--dark-shade);
  font-size: 20px;
  font-weight: 400;
  line-height: 36px;
}
.hero-section-img {
  display: flex;
  height: 754px;
  width: auto;
}
.hero-section-img > img {
  width: 100%;
  height: 100%;
}

.hero-section-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hero-section-image img {
  width: 75%;
  margin-top: 25px;
}
/* Hero Section Style Ends */

/* Skills Section Style */
.skills-section {
  display: flex;
  padding: 149.33px 85.33px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 106.667px;
}
.skill-score-section {
  display: flex;
  padding: 149.33px 85.33px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 106.667px;
  background-color: var(--bg-shade);
}
.skills-section-heading {
  color: var(--heading-color);
  font-size: 64px;
  font-weight: 700;
  line-height: 77px;
}
.skills-section-container {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  gap: 42.6px;
  grid-template-columns: repeat(3, 1fr);
}
.skills-section-card {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--bg-shade);
  min-height: 250px;
  border-bottom: 4px solid var(--bg-shade);
}
.skills-section-card:hover {
  border-bottom: 4px solid var(--primary);
}
.skills-section-card:hover .skills-section-description {
  color: var(--dark-shade);
}
.skills-section-img {
  display: flex;
  padding: 13.3px;
  justify-content: center;
  align-items: center;
  gap: 13.3px;
  border-radius: 10.6px;
  background: #fff;
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
}
.skills-section-card-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.skills-section-title {
  color: var(--heading-color);
  font-size: 32px;
  font-weight: 700;
  line-height: 45px;
}
.skills-section-description {
  color: var(--black);
  font-size: 21.3px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
}

/*skills-score*/
.skills-score-section {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  border-radius: 10.6px;
  background: var(--bg-shade);
  min-height: 250px;
  border-bottom: 4px solid var(--bg-shade);
}

.skills-score-section-container {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 42.6px;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
  text-align: center;
}

.skills-score-section-title {
  color: var(--heading-color);
  font-size: 26px;
  font-weight: 700;
  line-height: 45px;
  padding-bottom: 10px;
}

.skills-score-card {
  position: relative;
}

.skills-score-card:hover .circular-progressbar-wrapper {
  filter: blur(5px);
}

.circular-progressbar-img {
  width: auto;
  height: 70px;
}

.circular-progressbar {
  position: relative;
  z-index: 2;
}

.circular-progressbar-wrapper {
  position: relative;
  z-index: 1;
}

.score-display {
  display: none; /* Standaard wordt het niet weergegeven */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  color: white;
  z-index: 3;
  background-color: var(--heading-color);
  padding: 5px 10px;
  border-radius: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin-top: 25px;
}

.skills-score-card:hover .score-display {
  display: block; /* Wanneer erover wordt bewogen, wordt het weergegeven */
}
/* Skills Style Ends */

/* About Us */
.about-section {
  display: grid;
  padding: 133.3px 85.3px;
  align-items: center;
  gap: 114.6px;
  grid-template-columns: repeat(2, 1fr);
}
.about-section-img > img {
  width: 100%;
  height: 100%;
}
/* About Us Ends */

/* My Portfolio Starts */
.portfolio-section {
  display: flex;
  padding: 112px 85px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
}
.portfolio-container-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.portfolio-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 21.3px;
}
.portfolio-section-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 42.6px;
  width: 100%;
}
.portfolio-section-img {
  border-radius: 8px;
  width: 100%;
}
.portfolio-section-img > img {
  width: 100%;
}

.portfolio-section-img img{
  max-height: 500px;
  object-fit: contain;
}
.portfolio-section-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  flex: 1 0 0;
  background: #fff;
  box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.portfolio-section-card-content {
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  align-self: stretch;
}
.portfolio-section-title {
  color: var(--heading-color);
}
.portfolio-link {
  text-decoration: none;
  display: flex;
  gap: 16px;
  border-bottom: 1.33px solid var(--primary);
  padding-bottom: 10.6px;
  font-weight: 600;
}
.portfolio-section-card:hover path {
  stroke: #006b6a;
}
/* Portfolio Ends */

/* MySkillsScore */
.testimonial-section {
  display: flex;
  padding: 112px 84.3px;
  flex-direction: column;
  align-items: flex-start;
  gap: 80px;
  background-color: var(--bg-shade);
}
.sections-heading {
  color: var(--heading-color);
}
.testimonial-section-card {
  display: flex;
  padding: 42.6px;
  flex-direction: column;
  align-items: flex-start;
  gap: 42.6px;
  flex: 1 0 0;
  border-radius: 8px;
  border: 1.33px solid #006b6a;
}
.testimonial-section-card-reviews {
  display: flex;
  align-items: flex-start;
  gap: 5.33px;
}
.testimonial-section-card-author-detail {
  display: flex;
  align-items: center;
  gap: 21.3px;
}
.testimonial-author-name {
  font-weight: 600;
  color: var(--heading-color);
}
.testimonial-author-designation {
  color: var(--dark-shade);
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
}
/* MySkillsScore Ends */
.contact-section {
  display: flex;
  padding: 150px 85px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 64px;
}
.contact-form-container {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(5, auto);
  width: 40%;
  row-gap: 32px;
}
.container {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 32px;
  row-gap: 32px;
}
.contact-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10.6px;
  flex: 1 0 0;
  width: 100%;
}
.contact-input {
  display: flex;
  font-family: "Roboto";
  padding: 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  border: 1.333px solid var(--primary);
  background: var(--white);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Some browsers will not display the caret when using calc, so we put the fallback first */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat 98.5% !important; /* !important used for overriding all other customisations */
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="33" viewBox="0 0 32 33" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.5303 21.2052C16.2374 21.4981 15.7626 21.4981 15.4697 21.2052L7.82318 13.5587C7.53029 13.2658 7.53029 12.791 7.82318 12.4981L8.17674 12.1445C8.46963 11.8516 8.9445 11.8516 9.2374 12.1445L16 18.9071L22.7626 12.1445C23.0555 11.8516 23.5303 11.8516 23.8232 12.1445L24.1768 12.4981C24.4697 12.791 24.4697 13.2658 24.1768 13.5587L16.5303 21.2052Z" fill="%23033333"/></svg>')
    white no-repeat calc(100% - 20px); /* Better placement regardless of input width */
}
/*For IE*/
select::-ms-expand {
  display: none;
}
.checkbox-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10.6px;
  flex: 1 0 0;
  justify-content: flex-start;
}
input[type="checkbox"] {
  width: 21px;
  height: 21px;
  border: 0.15em solid var(--heading-color);
  border-radius: 0.15em;
  transform: translatey(-0.075em);
}
/* Contact End */

/* Footer Start */
/* Footer Starts */
.footer-container {
  display: flex;
  padding: 106.667px 85.333px;
  flex-direction: column;
  align-items: flex-start;
  background: var(--bg-shade);
  align-items: center;
  align-self: stretch;
}
.footer-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer-items > ul {
  list-style: none;
  display: flex;
  align-items: flex-start;
  gap: 42.667px;
  text-decoration: none;
}
.footer-items ul > li > a {
  text-decoration: none;
}
.footer-social-icon > ul {
  list-style: none;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
  text-decoration: none;
}
.divider {
  margin: 16px 0 42.67px;
  height: 1.333px;
  width: 100%;
  background: rgba(40, 41, 56, 0.55);
}
.footer-content-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.footer--content {
  color: var(--black);
  font-size: 18.667px;
  font-weight: 400;
  line-height: 28px;
}
.footer-img {
  width: 20%;
  height: auto;
}
/* Responsive Screens */
@media only screen and (max-width: 1800px) {
  .hero-section-title {
    font-size: 68px;
    line-height: 70px;
  }
  .skills-section-title {
    font-size: 28px;
    line-height: 40px;
  }
}

@media only screen and (max-width: 1600px) {
  .skills-section-heading {
    font-size: 54px;
    line-height: 70px;
  }
  .skills-section-container {
    gap: 16px;
  }
  .skills-section-card {
    gap: 28px;
  }
  .skills-section-card-content {
    gap: 20px;
  }
  .skills-section-title {
    font-size: 23px;
    line-height: 30px;
  }
  .skills-section-description {
    font-size: 17.333px;
    line-height: 27px;
  }
}

@media only screen and (max-width: 800px) {
  .skills-score-section-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 1200px) {
  .btn-outline-primary {
    display: none;
  }
  .hero-section {
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 70px;
  }
  .hero-section-title,
  .hero-section-description,
  .footer-content {
    text-align: center;
    font-size: 15px;
    line-height: normal;
  }
  .skills-section-container,
  .about-section,
  .portfolio-section-container,
  .portfolio-container-box,
  .footer-link-container,
  .footer-items > ul,
  .footer-content-container {
    display: flex;
    flex-direction: column;
  }
  .skills-section,
  .portfolio-container,
  .hero-section-content,
  .hero-section-content-box {
    align-items: center;
  }
  .portfolio-container-box {
    gap: 30px;
  }
  .skills-section,
  .testimonial-section,
  .contact-section,
  .footer-container {
    gap: 20px;
    padding-top: 70px;
    padding-bottom: 70px;
  }
  .about-section {
    gap: 20px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .testimonial-section-card {
    padding: 25px;
  }
  .hero-section-title,
  .skills-section-heading,
  .sections-heading {
    font-size: 35px;
    line-height: 40px;
  }
  .hero-section-content-box {
    gap: 10px;
  }
  .contact-form-container {
    width: 100%;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .footer-social-icon > ul,
  .footer-items > ul {
    padding: 0;
    align-items: center;
  }
  .divider {
    margin: 20px;
  }

  .skills-score-section-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Hamburger menu  */

.nav_hamburger {
  display: none;
  width: 1.875rem;
  height: 1.313rem;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  right: 25px;
}

.nav_hamburger_line {
  display: block;
  height: 0.188rem;
  width: 100%;
  background-color: #000000;
  border-radius: 0.625rem;
  transition: all ease-in-out 0.2s;
}

@media screen and (max-width: 1200px) {
  .nav_hamburger {
    display: flex;
    z-index: 200;
  }
  .navbar-items {
    display: flex;
  }
  .navbar-items {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255);
    top: -25rem;
    left: 0;
    width: 100%;
    transition: all ease-in-out 0.4s;
    min-width: 100vw;
  }
  .navbar-items ul {
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3rem 0 0.6rem;
    z-index: -100;
  }
  .navbar-items ul li {
    text-align: center;
  }
  .navbar-items ul li a {
    padding: 0.5rem;
  }
  .navbar-items {
    z-index: -1100;
    display: flex;
    flex-direction: column;
  }
  .navbar-items.active {
    top: 30px;
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
  }
  .nav_hamburger.active :nth-child(1) {
    transform: rotate(45deg) translate(0.45rem, 0.1875rem);
  }

  .nav_hamburger.active :nth-child(2) {
    opacity: 0;
  }

  .nav_hamburger.active :nth-child(3) {
    transform: rotate(-45deg) translate(0.45rem, -0.1875rem);
  }
}

/* Responsive Screens Ends*/
